export enum ConfigurationModeName {
    FLASH = "flash",
    ADVANCED = "advanced"
}

type ConfigurationModeItem = {
    mode: ConfigurationModeName;
}

export const CONFIGURATION_MODES: ConfigurationModeItem[] = [
    {mode: ConfigurationModeName.FLASH},
    {mode: ConfigurationModeName.ADVANCED}
];
