import {Alert, AlertSeverity, Box, BoxSpacing, Button, Color, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentJustify, FlexContentSpacing, Icon, LayoutColumns, SvgSirdataLogoWhite, Tag, TagStyle, Wrapper} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ConfigurationModeName, CONFIGURATION_MODES} from "../../utils/ConfigurationMode";
import {ConfigurationModeInformation} from "../../utils/ConfigurationModeInformation";
import {pathCmp, pathConfigurationFlashInformation, TranslationPortalFile} from "../../utils/constants";
import {PortalQueryString} from "../../api/model/PortalQueryString";

function ConfigurationMode() {
    const navigate = useNavigate();
    const {t: textConfigurationMode} = useTranslation(TranslationPortalFile.CONFIGURATION_MODE);

    const CONFIGURATION_MODE_INFORMATIONS: { mode: ConfigurationModeName; info: ConfigurationModeInformation }[] = CONFIGURATION_MODES.map(({mode}) => {
        return {mode, info: textConfigurationMode(`modes.${mode}`, {returnObjects: true}) as ConfigurationModeInformation};
    });

    const handleConfigModeChoice = (mode: ConfigurationModeName) => {
        switch (mode) {
            case ConfigurationModeName.ADVANCED:
                navigate(`${pathCmp}?${PortalQueryString.ID}=NEW`);
                break;
            case ConfigurationModeName.FLASH:
                navigate(pathConfigurationFlashInformation);
                break;
            default:
                break;
        }
    };

    return (
        <Wrapper cssClass="onboarding">
            <div className="onboarding-page">
                <div className="onboarding-header">
                    <div className="main-logo">
                        <SvgSirdataLogoWhite/>
                    </div>
                    <div className="title"><span>{textConfigurationMode("title")}</span></div>
                    <div className="description">{textConfigurationMode("description")}</div>
                </div>
                <div className="onboarding-form onboarding-form--large">
                    <LayoutColumns>
                        {CONFIGURATION_MODE_INFORMATIONS.map(({mode, info}) =>
                            <Box key={mode} spacing={BoxSpacing.LARGE}>
                                <FlexContent
                                    key={mode}
                                    direction={FlexContentDirection.COLUMN}
                                    spacing={FlexContentSpacing.MEDIUM}
                                    justify={FlexContentJustify.SPACE_BETWEEN}
                                    alignment={FlexContentAlignment.START}
                                    cssClass="configuration-mode"
                                >
                                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.SMALL} alignment={FlexContentAlignment.START} cssClass="configuration-mode__content">
                                        <Tag
                                            label={info.time}
                                            style={TagStyle.DEFAULT_CYAN_LIGHT}
                                            customColor={{borderColor: Color.CYAN_LIGHT}}
                                            icon={{name: "timer", outlined: true}}
                                        />
                                        <div className="configuration-mode__content__title">{info.title}</div>
                                        <div>{info.description}</div>
                                        <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL} cssClass="configuration-mode__content__steps">
                                            {info.steps.map((step) =>
                                                <div key={step} className="configuration-mode__content__steps__item">
                                                    <Icon name="east" cssClass="configuration-mode__content__steps__item__icon"/>
                                                    <span>{step}</span>
                                                </div>
                                            )}
                                        </FlexContent>
                                        {info.indication &&
                                            <Alert severity={AlertSeverity.INFO} text={info.indication} fullWidth/>
                                        }
                                    </FlexContent>
                                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM} alignment={FlexContentAlignment.CENTER} cssClass="configuration-mode__footer">
                                        <img src={textConfigurationMode("consent_mode_compliant.image")} title={textConfigurationMode("consent_mode_compliant.name")} alt={textConfigurationMode("consent_mode_compliant.name")}/>
                                        <Button onClick={() => handleConfigModeChoice(mode)} icon={{name: "east"}} reverse>{textConfigurationMode("setup")}</Button>
                                    </FlexContent>
                                </FlexContent>
                            </Box>
                        )}
                    </LayoutColumns>
                </div>
            </div>
        </Wrapper>
    );
}

export default ConfigurationMode;
