import {useState} from "react";
import clsx from "clsx";
import {Box, BoxRadius, Icon, Color, Accordion, AccordionItem, Tag, TagSize, TagStyle, AccordionItemContent, Button, FlexContent, FlexContentDirection, FlexContentSpacing, FlexContentAlignment} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import ModalCrossSalesContact from "../modal/ModalCrossSalesContact";
import {CrossSalesProduct} from "../../utils/CrossSalesProduct";
import {TranslationPortalFile} from "../../utils/constants";

const CrossSales = ({cssClass}: {cssClass?: string}) => {
    const {t: textCrossSales} = useTranslation(TranslationPortalFile.CROSS_SALES);
    const [open, setOpen] = useState(false);

    return (
        <Box radius={BoxRadius.LG} cssClass={clsx("cross-sales", cssClass)}>
            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM}>
                <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XSMALL}>
                    <Icon name="control_point_duplicate" outlined colorIcon={Color.GREEN}/>
                    <span className="h2 cross-sales__title">{textCrossSales("title")}</span>
                </FlexContent>
                <span>{textCrossSales("subtitle")}</span>
                <Accordion>
                    {(textCrossSales("product", {returnObjects: true}) as CrossSalesProduct[]).map(({name, price, description}, index) =>
                        <AccordionItem
                            key={name}
                            heading={
                                <FlexContent cssClass="h3" direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER}>
                                    {name}<Tag label={price} size={TagSize.XSMALL} style={TagStyle.DEFAULT_OCEAN}/>
                                </FlexContent>
                            }
                            startOpen={index === 0}
                        >
                            <AccordionItemContent>
                                <span className="cross-sales__accordion-content" dangerouslySetInnerHTML={{__html: description}}/>
                            </AccordionItemContent>
                        </AccordionItem>
                    )}
                </Accordion>
                <Button onClick={() => setOpen(true)}>{textCrossSales("action.interested")}</Button>
            </FlexContent>
            <ModalCrossSalesContact active={open} onClose={() => setOpen(false)}/>
        </Box>
    );
};

export default CrossSales;
