import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Action, ActionsMenu, Alert, AlertSeverity, Button, ButtonStyle, CodeBlock, CodeBlockLanguage, Color, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentMobileDirection, FlexContentSpacing, Icon, LayoutRows, Loadable, MainContentPageHeader, Paragraph, SvgAmp, Tabs} from "@sirdata/ui-lib";
import INTEGRATION_TEXTS from "./IntegrationTexts";
import {session} from "../../api/ApiSession";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigLanguage} from "../../api/model/cmp/config/CmpConfigLanguage";
import {CmpConfigStatus} from "../../api/model/cmp/config/CmpConfigStatus";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {Locale} from "../../common/utils/Locale";
import ModalCreateConfigConfirmation from "../../component/modal/ModalCreateConfigConfirmation";
import ModalSendMailIntegration from "../../component/modal/ModalSendMailIntegration";
import {ConfigHeader, CrossSales, InstallationStep, InstallationSteps, ModuleDownload} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {CmpConfigIntegrationType} from "../../utils/CmpConfigIntegrationType";
import {CmpPreviewHelper} from "../../utils/CmpPreviewHelper";
import CmpConfigStepper, {CmpConfigStepName} from "../../utils/CmpConfigStepper";
import {CmpConfigSettings} from "../../api/model/cmp/config/CmpConfigSettings";
import {UIEventManager} from "../../common/utils/UIEventManager";

function ConfigIntegration() {
    const {id: configId} = useParams() as {id: string};
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textConfigAddOns} = useTranslation(TranslationPortalFile.CONFIG_ADD_ONS);
    const {t: textConfigIntegration} = useTranslation(TranslationPortalFile.CONFIG_INTEGRATION);

    const [cmpConfig, setCmpConfig] = useState<CmpConfig>(new CmpConfig());
    const [currentTextLanguage, setCurrentTextLanguage] = useState<CmpConfigLanguage>(CmpConfigLanguage.getByName(Locale.getCurrentLocale() as string));
    const [privacyText, setPrivacyText] = useState("");

    const [isEditMode, setEditMode] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [isShowModalCreateConfigConfirmation, setShowModalCreateConfigConfirmation] = useState(false);
    const [activeIntegrationTypeMail, setActiveIntegrationTypeMail] = useState<CmpConfigIntegrationType>();
    const [isUpdating, setUpdating] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const newCmpConfig = await session.restCmpConfig.get(configId);
                setCmpConfig(newCmpConfig);
                setEditMode(!newCmpConfig.step);

                if (newCmpConfig.step === CmpConfigStepper.getPreviousStep(CmpConfigStepName.INTEGRATION)?.name || newCmpConfig.step === CmpConfigStepName.INFO) {
                    if (newCmpConfig.step === CmpConfigStepName.INFO) {
                        setShowModalCreateConfigConfirmation(true);
                    }
                    newCmpConfig.step = "";
                    newCmpConfig.status = CmpConfigStatus.ACTIVE;
                    await session.restCmpConfig.update(newCmpConfig);
                }
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [configId]);

    useEffect(() => {
        setPrivacyText(INTEGRATION_TEXTS[currentTextLanguage.name]);
    }, [currentTextLanguage]);

    const getAmpHeadScript = (): string => {
        const rows = [];
        rows.push("<script async custom-element=\"amp-consent\" src=\"https://cdn.ampproject.org/v0/amp-consent-0.1.js\"></script>");
        rows.push("<meta name=\"amp-consent-blocking\" content=\"amp-analytics,amp-ad\">");
        return rows.join("\n");
    };

    const getAmpToolbarScript = (): string => {
        const row = "<button id=\"promptConsentUI\" class=\"btn\" on=\"tap:consent.prompt(consent=sirdata)\" role=\"button\">My consent choices</button>";
        return row;
    };

    const handleOpenPreview = () => {
        CmpPreviewHelper.openPreview(cmpConfig);
    };

    const updateExternalGoogleAnalytics = async (enabled: boolean) => {
        try {
            setUpdating(true);
            const newCmpConfig = new CmpConfig(cmpConfig);
            newCmpConfig.settings = new CmpConfigSettings(newCmpConfig.settings);
            newCmpConfig.settings.external.googleAnalytics = enabled;
            const updatedConfig = await session.restCmpConfig.update(newCmpConfig);
            setCmpConfig(updatedConfig);
        } catch (e) {
            UIEventManager.alert(textConfigAddOns("error.update_add_ons"), AlertSeverity.DANGER);
        } finally {
            setUpdating(false);
        }
    };

    const buildCrossSales = () => <CrossSales cssClass="integration__cross-sales"/>;

    return (
        <Wrapper>
            <MainHeader/>
            <ConfigHeader
                step={CmpConfigStepName.INTEGRATION}
                editMode={isEditMode}
                hasUnsavedChanges={false}
                onSave={() => Promise.resolve()}
                onOpenPreview={handleOpenPreview}
            />
            <MainContent>
                <MainContentPageHeader title={textConfigIntegration("title")} cssClass="configuration-main-title"/>
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <Tabs
                            headers={[
                                {label: textConfigIntegration(`tab.${CmpConfigIntegrationType.WEB}`), icon: {name: "web"}},
                                {label: textConfigIntegration(`tab.${CmpConfigIntegrationType.GTM}`), icon: {name: "dns"}},
                                {label: textConfigIntegration(`tab.${CmpConfigIntegrationType.CMS}`), icon: {name: "dashboard_customize"}},
                                {label: textConfigIntegration(`tab.${CmpConfigIntegrationType.AMP}`), icon: <SvgAmp/>}
                            ]}
                        >
                            <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XLARGE} mobileDirection={FlexContentMobileDirection.COLUMN}>
                                <div className="integration__notices">
                                    {cmpConfig.settings.external.googleAnalytics ?
                                        <Alert text={textConfigIntegration("web.google_analytics_active")} severity={AlertSeverity.SUCCESS}/> :
                                        <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.MEDIUM}>
                                            <Alert text={textConfigIntegration("web.google_analytics_inactive")} severity={AlertSeverity.DANGER}/>
                                            <Button
                                                style={ButtonStyle.PRIMARY_MIDNIGHT_LIGHT}
                                                onClick={() => updateExternalGoogleAnalytics(true)}
                                                loading={isUpdating}
                                            >
                                                {textConfigIntegration("web.activate_google_analytics")}
                                            </Button>
                                        </FlexContent>
                                    }
                                    <hr/>
                                    <InstallationSteps>
                                        <InstallationStep key={1} index={1} title={textConfigIntegration("web.add_script.title")}>
                                            <Paragraph><span dangerouslySetInnerHTML={{__html: textConfigIntegration("web.add_script.description")}}/></Paragraph>
                                            <Alert text={textConfigIntegration("web.add_script.alert")} severity={AlertSeverity.WARNING}/>
                                            <CodeBlock
                                                header={textConfigIntegration("snippet")}
                                                language={CodeBlockLanguage.HTML}
                                                code={cmpConfig.getWebScript()}
                                                copiable
                                            />
                                        </InstallationStep>
                                        <InstallationStep key={2} index={2} title={textConfigIntegration("web.add_text_tcf.title")}>
                                            <Paragraph><span dangerouslySetInnerHTML={{__html: textConfigIntegration("web.add_text_tcf.description")}}/></Paragraph>
                                            <Alert text={textConfigIntegration("web.add_text_tcf.alert")} severity={AlertSeverity.WARNING}/>
                                            <div className="integration__notices__select-language">
                                                {textConfigIntegration("select_language")} : <span className="integration__notices__select-language__selected">{t(`language.${currentTextLanguage.name}`)}</span>
                                                <ActionsMenu
                                                    iconTooltip={{icon: {name: "language"}, text: textConfigIntegration("switch_lang")}}
                                                    items={CmpConfigLanguage.values()
                                                        .sort((a, b) => t(`language.${a.name}`) > t(`language.${b.name}`) ? 1 : -1)
                                                        .map((it) => ({label: t(`language.${it.name}`), onClick: () => setCurrentTextLanguage(it)}))}
                                                />
                                            </div>
                                            <CodeBlock
                                                header={textConfigIntegration("header_text_tcf")}
                                                language={CodeBlockLanguage.HTML}
                                                code={privacyText}
                                                copiable
                                                wrapLongLines
                                            />
                                        </InstallationStep>
                                    </InstallationSteps>
                                    <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.SMALL}>
                                        <div className="integration__notices__content-separator">
                                            <Icon name="more_vert" colorIcon={Color.GREY}/>
                                        </div>
                                        <Button icon={Action.SEND.icon} onClick={() => setActiveIntegrationTypeMail(CmpConfigIntegrationType.WEB)}>
                                            {textConfigIntegration("send_by_email.button")}
                                        </Button>
                                    </FlexContent>
                                </div>
                                {buildCrossSales()}
                            </FlexContent>
                            <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XLARGE} mobileDirection={FlexContentMobileDirection.COLUMN}>
                                <div className="integration__notices">
                                    {cmpConfig.settings.external.googleAnalytics ?
                                        <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.MEDIUM}>
                                            <Alert text={textConfigIntegration("gtm.google_analytics_active")} severity={AlertSeverity.DANGER}/>
                                            <Button
                                                style={ButtonStyle.PRIMARY_MIDNIGHT_LIGHT}
                                                onClick={() => updateExternalGoogleAnalytics(false)}
                                                loading={isUpdating}
                                            >
                                                {textConfigIntegration("gtm.deactivate_google_analytics")}
                                            </Button>
                                        </FlexContent> :
                                        <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                                            <Alert text={textConfigIntegration("gtm.google_analytics_inactive_warning")} severity={AlertSeverity.WARNING}/>
                                            <Alert text={textConfigIntegration("gtm.google_analytics_inactive_success")} severity={AlertSeverity.SUCCESS}/>
                                        </FlexContent>
                                    }
                                    <hr/>
                                    <Paragraph>{textConfigIntegration("gtm.introduction")}</Paragraph>
                                    <InstallationSteps>
                                        <InstallationStep key={1} index={1} title={textConfigIntegration("gtm.step1.title")}>
                                            <Paragraph><span dangerouslySetInnerHTML={{__html: textConfigIntegration("gtm.step1.description")}}/></Paragraph>
                                            <div className="integration__notices__image">
                                                <img src={`/images/config/integration/gtm/${Locale.getCurrentLocale()}/step_1.png`} alt="step_1"/>
                                            </div>
                                        </InstallationStep>
                                        <InstallationStep key={2} index={2} title={textConfigIntegration("gtm.step2.title")}>
                                            <div className="integration__notices__image">
                                                <img src={`/images/config/integration/gtm/${Locale.getCurrentLocale()}/step_2.png`} alt="step_2"/>
                                            </div>
                                        </InstallationStep>
                                        <InstallationStep key={3} index={3} title={textConfigIntegration("gtm.step3.title")}>
                                            <div className="integration__notices__image">
                                                <img src={`/images/config/integration/gtm/${Locale.getCurrentLocale()}/step_3.png`} alt="step_3"/>
                                            </div>
                                        </InstallationStep>
                                        <InstallationStep key={4} index={4} title={textConfigIntegration("gtm.step4.title")}>
                                            <Paragraph><span dangerouslySetInnerHTML={{__html: textConfigIntegration("gtm.step4.description")}}/></Paragraph>
                                            <div className="integration__notices__image">
                                                <img src={`/images/config/integration/gtm/${Locale.getCurrentLocale()}/step_4.png`} alt="step_4"/>
                                            </div>
                                        </InstallationStep>
                                        <InstallationStep key={5} index={5} title={textConfigIntegration("gtm.step5.title")}>
                                            <Paragraph><span dangerouslySetInnerHTML={{__html: textConfigIntegration("gtm.step5.description")}}/></Paragraph>
                                            <div className="integration__notices__image">
                                                <img src={`/images/config/integration/gtm/${Locale.getCurrentLocale()}/step_5.png`} alt="step_5"/>
                                            </div>
                                        </InstallationStep>
                                        <InstallationStep key={6} index={6} title={textConfigIntegration("gtm.step6.title")}>
                                            <Paragraph><span dangerouslySetInnerHTML={{__html: textConfigIntegration("gtm.step6.description")}}/></Paragraph>
                                            <div className="integration__notices__image">
                                                <img src={`/images/config/integration/gtm/${Locale.getCurrentLocale()}/step_6.png`} alt="step_6"/>
                                            </div>
                                            <Alert text={textConfigIntegration("gtm.step6.alert")}/>
                                        </InstallationStep>
                                        <InstallationStep key={7} index={7} title={textConfigIntegration("gtm.step7.title")}>
                                            <div className="integration__notices__image">
                                                <img src={`/images/config/integration/gtm/${Locale.getCurrentLocale()}/step_7.png`} alt="step_7"/>
                                            </div>
                                            <Paragraph><span dangerouslySetInnerHTML={{__html: textConfigIntegration("gtm.step7.description")}}/></Paragraph>
                                            <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.LARGE} mobileDirection={FlexContentMobileDirection.COLUMN}>
                                                <CodeBlock
                                                    header={textConfigIntegration("gtm.step7.partner_id")}
                                                    language={CodeBlockLanguage.MARKDOWN}
                                                    code={cmpConfig.id_partner.toString()}
                                                    copiable
                                                />
                                                <CodeBlock
                                                    header={textConfigIntegration("gtm.step7.config_id")}
                                                    language={CodeBlockLanguage.MARKDOWN}
                                                    code={cmpConfig.id}
                                                    copiable
                                                />
                                            </FlexContent>
                                            <Alert text={textConfigIntegration("gtm.step7.alert")}/>
                                        </InstallationStep>
                                        <InstallationStep key={8} index={8} title={textConfigIntegration("gtm.step8.title")}>
                                            <Paragraph><span dangerouslySetInnerHTML={{__html: textConfigIntegration("gtm.step8.description")}}/></Paragraph>
                                            <div className="integration__notices__image">
                                                <img src={`/images/config/integration/gtm/${Locale.getCurrentLocale()}/step_8.png`} alt="step_8"/>
                                            </div>
                                        </InstallationStep>
                                        <InstallationStep key={9} index={9} title={textConfigIntegration("gtm.step9.title")}>
                                            <Paragraph><span dangerouslySetInnerHTML={{__html: textConfigIntegration("gtm.step9.description")}}/></Paragraph>
                                            <Alert text={textConfigIntegration("gtm.step9.alert")} severity={AlertSeverity.WARNING}/>
                                            <div className="integration__notices__image">
                                                <img src={`/images/config/integration/gtm/${Locale.getCurrentLocale()}/step_9.png`} alt="step_9"/>
                                            </div>
                                        </InstallationStep>
                                    </InstallationSteps>
                                    <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.SMALL}>
                                        <div className="integration__notices__content-separator">
                                            <Icon name="more_vert" colorIcon={Color.GREY}/>
                                        </div>
                                        <Button icon={Action.SEND.icon} onClick={() => setActiveIntegrationTypeMail(CmpConfigIntegrationType.GTM)}>
                                            {textConfigIntegration("send_by_email.button")}
                                        </Button>
                                    </FlexContent>
                                </div>
                                {buildCrossSales()}
                            </FlexContent>
                            <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XLARGE} mobileDirection={FlexContentMobileDirection.COLUMN}>
                                <div className="integration__notices">
                                    {cmpConfig.settings.external.googleAnalytics ?
                                        <Alert text={textConfigIntegration("web.google_analytics_active")} severity={AlertSeverity.SUCCESS}/> :
                                        <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.MEDIUM}>
                                            <Alert text={textConfigIntegration("web.google_analytics_inactive")} severity={AlertSeverity.DANGER}/>
                                            <Button
                                                style={ButtonStyle.PRIMARY_MIDNIGHT_LIGHT}
                                                onClick={() => updateExternalGoogleAnalytics(true)}
                                                loading={isUpdating}
                                            >
                                                {textConfigIntegration("web.activate_google_analytics")}
                                            </Button>
                                        </FlexContent>
                                    }
                                    <hr/>
                                    <Tabs
                                        headers={[
                                            {label: textConfigIntegration("cms.prestashop.label")},
                                            {label: textConfigIntegration("cms.drupal.label")}
                                        ]}
                                    >
                                        <InstallationSteps>
                                            <InstallationStep key={1} index={1} title={textConfigIntegration("cms.prestashop.step_1.title")}>
                                                <ModuleDownload file={textConfigIntegration("cms.prestashop.step_1.file", {returnObjects: true})}/>
                                            </InstallationStep>
                                            <InstallationStep key={2} index={2} title={textConfigIntegration("cms.prestashop.step_2.title")}>
                                                {(textConfigIntegration("cms.prestashop.step_2.description", {returnObjects: true}) as string[]).map((it) =>
                                                    <Paragraph key={it}>• {it}</Paragraph>
                                                )}
                                            </InstallationStep>
                                            <InstallationStep key={3} index={3} title={textConfigIntegration("cms.prestashop.step_3.title")}>
                                                <div className="integration__notices__image">
                                                    <img src={"/images/config/integration/cms/prestashop/step_3.png"} alt="step_3"/>
                                                </div>
                                                <Paragraph><span dangerouslySetInnerHTML={{__html: textConfigIntegration("cms.prestashop.step_3.description")}}/></Paragraph>
                                                <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.LARGE} mobileDirection={FlexContentMobileDirection.COLUMN}>
                                                    <CodeBlock
                                                        header={textConfigIntegration("cms.prestashop.step_3.sirdata_account_id")}
                                                        language={CodeBlockLanguage.MARKDOWN}
                                                        code={cmpConfig.id_partner.toString()}
                                                        copiable
                                                    />
                                                    <CodeBlock
                                                        header={textConfigIntegration("cms.prestashop.step_3.site_id")}
                                                        language={CodeBlockLanguage.MARKDOWN}
                                                        code={cmpConfig.id}
                                                        copiable
                                                    />
                                                </FlexContent>
                                            </InstallationStep>
                                        </InstallationSteps>
                                        <InstallationSteps>
                                            <InstallationStep key={1} index={1} title={textConfigIntegration("cms.drupal.step_1.title")}>
                                                <div className="integration__notices__image">
                                                    <img src={"/images/config/integration/cms/drupal/step_1.png"} alt="step_1"/>
                                                </div>
                                                {(textConfigIntegration("cms.drupal.step_1.description", {returnObjects: true}) as string[]).map((it) =>
                                                    <Paragraph key={it}>• <span dangerouslySetInnerHTML={{__html: it}}/></Paragraph>
                                                )}
                                            </InstallationStep>
                                            <InstallationStep key={2} index={2} title={textConfigIntegration("cms.drupal.step_2.title")}>
                                                <div className="integration__notices__image">
                                                    <img src={"/images/config/integration/cms/drupal/step_2.png"} alt="step_2"/>
                                                </div>
                                                {(textConfigIntegration("cms.drupal.step_2.description", {returnObjects: true}) as string[]).map((it) =>
                                                    <Paragraph key={it}>• <span dangerouslySetInnerHTML={{__html: it}}/></Paragraph>
                                                )}
                                            </InstallationStep>
                                            <InstallationStep key={3} index={3} title={textConfigIntegration("cms.drupal.step_3.title")}>
                                                <div className="integration__notices__image">
                                                    <img src={"/images/config/integration/cms/drupal/step_3.png"} alt="step_3"/>
                                                </div>
                                                {(textConfigIntegration("cms.drupal.step_3.description", {returnObjects: true}) as string[]).map((it) =>
                                                    <Paragraph key={it}>• <span dangerouslySetInnerHTML={{__html: it}}/></Paragraph>
                                                )}
                                                <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.LARGE} mobileDirection={FlexContentMobileDirection.COLUMN}>
                                                    <CodeBlock
                                                        header={textConfigIntegration("cms.drupal.step_3.partner_id")}
                                                        language={CodeBlockLanguage.MARKDOWN}
                                                        code={cmpConfig.id_partner.toString()}
                                                        copiable
                                                    />
                                                    <CodeBlock
                                                        header={textConfigIntegration("cms.drupal.step_3.config_id")}
                                                        language={CodeBlockLanguage.MARKDOWN}
                                                        code={cmpConfig.id}
                                                        copiable
                                                    />
                                                </FlexContent>
                                            </InstallationStep>
                                        </InstallationSteps>
                                    </Tabs>
                                </div>
                                {buildCrossSales()}
                            </FlexContent>
                            <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XLARGE} mobileDirection={FlexContentMobileDirection.COLUMN}>
                                <div className="integration__notices">
                                    <Alert text={textConfigIntegration("amp.information")} severity={AlertSeverity.WARNING}/>
                                    <InstallationSteps>
                                        <InstallationStep key={1} index={1} title={textConfigIntegration("amp.add_head_script.title")}>
                                            <Paragraph><span dangerouslySetInnerHTML={{__html: textConfigIntegration("amp.add_head_script.description")}}/></Paragraph>
                                            <CodeBlock header={textConfigIntegration("snippet")} language={CodeBlockLanguage.HTML} code={getAmpHeadScript()} copiable/>
                                        </InstallationStep>
                                        <InstallationStep key={2} index={2} title={textConfigIntegration("amp.add_body_script.title")}>
                                            <Paragraph><span dangerouslySetInnerHTML={{__html: textConfigIntegration("amp.add_body_script.description")}}/></Paragraph>
                                            <CodeBlock
                                                header={textConfigIntegration("snippet")}
                                                language={CodeBlockLanguage.HTML}
                                                code={cmpConfig.getAmpScript()}
                                                copiable
                                                wrapLongLines
                                            />
                                        </InstallationStep>
                                        <InstallationStep key={3} index={3} title={textConfigIntegration("amp.add_toolbar_link.title")}>
                                            <Paragraph><span dangerouslySetInnerHTML={{__html: textConfigIntegration("amp.add_toolbar_link.description")}}/></Paragraph>
                                            <CodeBlock
                                                header={textConfigIntegration("example")}
                                                language={CodeBlockLanguage.HTML}
                                                code={getAmpToolbarScript()}
                                                copiable
                                                wrapLongLines
                                            />
                                        </InstallationStep>
                                    </InstallationSteps>
                                </div>
                                {buildCrossSales()}
                            </FlexContent>
                        </Tabs>
                    </Loadable>
                </LayoutRows>
                <ModalCreateConfigConfirmation
                    active={isShowModalCreateConfigConfirmation}
                    onClose={() => setShowModalCreateConfigConfirmation(false)}
                    onOpenPreview={handleOpenPreview}
                />
                <ModalSendMailIntegration
                    cmpConfig={cmpConfig}
                    integrationType={activeIntegrationTypeMail}
                    onClose={() => setActiveIntegrationTypeMail(undefined)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default ConfigIntegration;
