import React, {FunctionComponent} from "react";
import {Alert, AlertSeverity, Button, Color, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentJustify, FlexContentSpacing, Icon, ModalContent, ModalNew, Tag, TagStyle} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {ConfigurationModeName, CONFIGURATION_MODES} from "../../utils/ConfigurationMode";
import {ConfigurationModeInformation} from "../../utils/ConfigurationModeInformation";

type ModalConfigModeProps = {
    active: boolean;
    onClose: () => void;
    onClick: (mode: ConfigurationModeName) => void;
}

const ModalConfigMode: FunctionComponent<ModalConfigModeProps> = ({active, onClose, onClick}) => {
    const {t: textConfigurationMode} = useTranslation(TranslationPortalFile.CONFIGURATION_MODE);

    const CONFIGURATION_MODE_INFORMATIONS: { mode: ConfigurationModeName; info: ConfigurationModeInformation }[] = CONFIGURATION_MODES.map(({mode}) => {
        return {mode, info: textConfigurationMode(`modes.${mode}`, {returnObjects: true}) as ConfigurationModeInformation};
    });

    return (
        <ModalNew onClose={onClose} active={active} keepMounted>
            <ModalContent>
                <FlexContent direction={FlexContentDirection.ROW} cssClass="modal-create-config">
                    {CONFIGURATION_MODE_INFORMATIONS.map(({mode, info}) =>
                        <FlexContent
                            key={mode}
                            direction={FlexContentDirection.COLUMN}
                            spacing={FlexContentSpacing.MEDIUM}
                            justify={FlexContentJustify.SPACE_BETWEEN}
                            alignment={FlexContentAlignment.START}
                            cssClass="modal-create-config__mode"
                        >
                            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.SMALL} alignment={FlexContentAlignment.START} cssClass="modal-create-config__mode__content">
                                <Tag
                                    label={info.time}
                                    style={TagStyle.DEFAULT_CYAN_LIGHT}
                                    customColor={{borderColor: Color.CYAN_LIGHT}}
                                    icon={{name: "timer", outlined: true}}
                                />
                                <div className="modal-create-config__mode__content__title">{info.title}</div>
                                <div className="modal-create-config__mode__content__description">{info.description}</div>
                                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                                    {info.steps.map((step) =>
                                        <div key={step} className="modal-create-config__mode__content__steps__item">
                                            <Icon name="east" cssClass="modal-create-config__mode__content__steps__item__icon"/>
                                            <span>{step}</span>
                                        </div>
                                    )}
                                </FlexContent>
                                {info.indication &&
                                    <Alert severity={AlertSeverity.INFO} text={info.indication} fullWidth cssClass="modal-create-config__mode__content__alert"/>
                                }
                            </FlexContent>
                            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM} alignment={FlexContentAlignment.CENTER} cssClass="modal-create-config__mode__footer">
                                <img src={textConfigurationMode("consent_mode_compliant.image")} title={textConfigurationMode("consent_mode_compliant.name")} alt={textConfigurationMode("consent_mode_compliant.name")}/>
                                <Button onClick={() => onClick(mode)} icon={{name: "east"}} reverse>{textConfigurationMode("setup")}</Button>
                            </FlexContent>
                        </FlexContent>
                    )}
                </FlexContent>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalConfigMode;
